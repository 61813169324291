<template>
	<div class="CookieTest">
		<a-textarea v-model="cookie" :rows="10"></a-textarea>
		<div>
			<b>Key:</b>
			<a-input v-model="key"></a-input>
		</div>
		<div>
			<b>Value:</b>
			<a-textarea v-model="value"></a-textarea>
		</div>
		<div>
			<b>过期时间:</b>
			<div>
				<a-date-picker v-model="date" :locale="locale" />
				<a-time-picker v-model="time" />
			</div>
		</div>
		<a-button type="primary" @click="setCookie">添加</a-button>
	</div>
</template>

<script>
import Cookies from "js-cookie";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
export default {
	components: {},
	name: "CookieTest",
	data() {
		return {
			cookie: "",
			key: "",
			value: "",
			locale,
			date: "",
			time: "",
		};
	},
	mounted() {
		this.getCookie();
	},
	methods: {
		getCookie() {
			let cookie_str = document.cookie;
			// let cookie_arr = cookie_str.split("; ");
			// let cookie_obj = {};
			// for (let i = 0; i < cookie_arr.length; i++) {
			// 	let cookie_item = cookie_arr[i].split("=");
			// 	cookie_obj[cookie_item[0]] = cookie_item[1];
			// }
			// this.cookie = JSON.stringify(cookie_obj);
			cookie_str = cookie_str.replace(/; /g, ";\n");
			this.cookie = cookie_str;
		},
		setCookie() {
			if (this.key && this.value) {
				let date_str = "";
				if (this.date) {
					date_str = this.date.format("YYYY-MM-DD");
				}
				if (this.date && this.time) {
					date_str += ` ${this.time.format("HH:mm:ss")}`;
				}
				if (date_str) {
					Cookies.set(this.key, this.value, { expires: new Date(date_str) });
				} else {
					Cookies.set(this.key, this.value);
				}
				this.getCookie();
				this.$message.success("添加成功");
				this.key = "";
				this.value = "";
				this.date = null;
				this.time = null;
			} else {
				this.$message.error("请输入key和value");
			}
		},
	},
};
</script>
<style lang="less" scoped>
.CookieTest {
	padding: 100px;
}
</style>
